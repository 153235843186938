// Override default variables before the import
$white: #ffffff;

$theme-colors: (
    "light":      #cbd2d1,
    "dark":       #4b88a2,
    "primary":    #244d93,
    "secondary":  #933424,
    "info":       #9b832f,
    "accent1":    #f8ce3d,
    "accent2":    #939393,
    "accent3":    #e2b3a1,
    "success":    #12834c,
    "warning":    #e6ce16,
    "danger":     #f71a0a,
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
