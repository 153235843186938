@font-face {
  font-family: 'TrendaRegular';
  src: url("fonts/Trenda-Regular/Trenda-Regular.eot");
  /* IE9 Compat Modes */
  src: url("fonts/Trenda-Regular/Trenda-Regular.eot?#iefix") format("embedded-opentype"), url("fonts/Trenda-Regular/Trenda-Regular.woff") format("woff"), url("fonts/Trenda-Regular/Trenda-Regular.ttf") format("truetype"), url("fonts/Trenda-Regular/Trenda-Regular.svg#Trenda-Regular") format("svg");
  /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
  }

#root {
  padding: 15px;
  margin: auto;
  max-width: 1150px;
  font-family: TrendaRegular;
}

.brick-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 15px;
  background-color: #eee;
}

.brick-wrapper-100 {
  width: 500px;
  height: 250px;
  margin: 0px 0px 20px 0px;
  background-image: url(../images/brick-100.png);
  background-image: -webkit-image-set(url(../images/brick-100.webp) 1x);
  background-repeat: no-repeat;
  background-size: cover;
  line-height: 1;
  overflow-wrap: normal;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.brick-wrapper-500 {
  width: 500px;
  height: 250px;
  margin: 0px 0px 20px 0px;
  background-image: url(../images/brick-500.png);
  background-image: -webkit-image-set(url(../images/brick-500.webp) 1x);
  background-repeat: no-repeat;
  background-size: cover;
  line-height: 1;
  overflow-wrap: normal;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.brick-wrapper-1000 {
  width: 500px;
  height: 500px;
  margin: 0px 0px 20px 0px;
  background-image: url(../images/brick-1000.png);
  background-image: -webkit-image-set(url(../images/brick-1000.webp) 1x);
  background-repeat: no-repeat;
  background-size: cover;
  line-height: 1;
  overflow-wrap: normal;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.brick-wrapper-view {
  padding: 0px 0px;
  margin: -10px -10px;
}

.brick-wrapper-view-100 {
  width: 250px;
  height: 125px;
  background-image: url(../images/brick-100.png);
  background-image: -webkit-image-set(url(../images/brick-100.webp) 1x);
  background-repeat: no-repeat;
  background-size: cover;
  line-height: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.brick-wrapper-view-500 {
  width: 250px;
  height: 125px;
  background-image: url(../images/brick-500.png);
  background-image: -webkit-image-set(url(../images/brick-500.webp) 1x);
  background-repeat: no-repeat;
  background-size: cover;
  line-height: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.brick-wrapper-view-1000 {
  width: 250px;
  height: 250px;
  background-image: url(../images/brick-1000.png);
  background-image: -webkit-image-set(url(../images/brick-1000.webp) 1x);
  background-repeat: no-repeat;
  background-size: cover;
  line-height: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}


@media (max-width: 499px) {
.brick-wrapper-500, .brick-wrapper-100 {
  width: 345px;
  height: 173px;
  margin: 0px 0px 5px 0px;
  }
}

@media (min-width: 500px) and (max-width: 992px) {
  .brick-wrapper-100, .brick-wrapper-500 {
  width: 500px;
  height: 250px;
  }
}

@media (max-width: 499px) {
  .brick-wrapper-1000 {
    width: 345px;
    height: 345px;
    margin: 0px 0px 5px 0px;
    }
  }

@media (min-width: 500px) and (max-width: 992px) {
  .brick-wrapper-1000 {
  width: 500px;
  height: 500px;
  }
}

.brick-contents {
  height: 0px;
}

 .brick-contents-1000 {
  height: 100px;
}

@media (max-width: 499px) {
  .brick-contents-1000 {
    height: 10px;
    }
  }


.brick-contents-view-1000 {
  padding-bottom: 3px;
}

.brick-text-100 {
  color: #192120 !important;
  font-size: 2.5rem !important;
  font-weight: bold;
  line-height: 0.7;
}

.brick-text-500 {
  color: #e2cea2 !important;
  font-size: 2.5rem !important;
  font-weight: bold;
  line-height: 0.7;
}

.brick-text-1000 {
  color: #e2cea2 !important;
  font-size: 2.5rem !important;
  font-weight: bold;
}

@media (max-width: 499px) {
  .brick-text-100,
  .brick-text-500,
  .brick-text-1000 {
    font-size: 1.25rem !important;
    }
  }

@media (min-width: 500px) and (max-width: 992px) {
  .brick-text-100,
  .brick-text-500,
  .brick-text-1000 {
  font-size: 2rem !important;
  }
}

.brick-text-view-100 {
  color: #192120 !important;
  font-size: 1.1rem !important;
  font-weight: bold;
  box-sizing: border-box;
  line-height: 0.3;
}

.brick-text-view-500, .brick-text-view-1000 {
  color: #e2cea2 !important;
  font-size: 1.1rem !important;
  font-weight: bold;
  box-sizing: border-box;
  line-height: 0.3;
}

.brick-link {
  text-decoration: none;
  width: 100%;
  height: 100%;
}

.brick-search label {
  font-size: 1.2rem;
}

.brick-search .form-control::-webkit-input-placeholder {
  color: white !important;
}

.brick-search ::-moz-placeholder {
  /* Firefox 19+ */
  color: white !important;
}

.brick-search :-ms-input-placeholder {
  /* IE 10+ */
  color: white !important;
}

.react-bricks-app,
.page-item {
  margin: 0px !important;
}

.modal {
    text-align: center;
}

.modal-dialog {
    text-align: left; /* you'll likely want this */
    max-width: fit-content !important;
    width: auto !important;
    display: inline-block;
}
 .brick-button {
  margin-top: 10px;
  margin-bottom: 10px;
 }

.pagination > .active > a
{
    color: white;
    background-color: #933424 !Important;
    border: solid 1px black !Important;
}
